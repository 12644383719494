import { useTranslation } from 'next-i18next'

import type { QaHook } from '@knauf-group/ct-designs/utils/types'
import { Typography } from '@mui/material'

type NoResultsTextProps = QaHook

export const NoResultsText = (props: NoResultsTextProps) => {
  const { dataCy } = props
  const { t } = useTranslation('download-center', { keyPrefix: 'mainPage.filtersPanel' })

  return (
    // TODO do we need to specify fontSize or `pl`?
    <Typography
      sx={{ pl: 2, fontSize: '14px' }}
      variant="body2"
      color="text.secondary"
      noWrap
      data-cy={`${dataCy}__no-results`}
    >
      {t('noResults')}
    </Typography>
  )
}
