import { useClearRefinements } from 'react-instantsearch-core'
import { useTranslation } from 'next-i18next'

import type { QaHook } from '@knauf-group/ct-designs/utils/types'
import { Refresh } from '@mui/icons-material'
import type { Theme } from '@mui/material'
import { Button, useMediaQuery } from '@mui/material'
import { useAnalyticsTrackResetFilters } from 'src/hooks/analytics/useAnalyticsTracking'

const excludedAttributes = ['language', 'query']

export const ResetAllButton = ({ dataCy }: QaHook) => {
  const { trackResetFiltersEvent } = useAnalyticsTrackResetFilters({ excludedAttributes })
  const { t } = useTranslation('download-center', {
    keyPrefix: 'mainPage.filtersPanel.buttons',
  })
  const isScreenLgDown = useMediaQuery<Theme>((theme) => theme.breakpoints.down('lg'))

  const { refine, canRefine } = useClearRefinements({
    excludedAttributes,
  })

  const handleOnClick = () => {
    trackResetFiltersEvent(isScreenLgDown ? t('resetAll') : t('resetAllFilters'))
    refine()
  }

  return (
    <Button
      variant={isScreenLgDown ? 'text' : 'outlined'}
      size="medium"
      fullWidth={!isScreenLgDown}
      startIcon={<Refresh />}
      onClick={handleOnClick}
      disabled={!canRefine}
      data-cy={dataCy}
      sx={{ height: '40px', width: isScreenLgDown ? '50%' : '100%' }}
    >
      {isScreenLgDown ? t('resetAll') : t('resetAllFilters')}
    </Button>
  )
}
