import { useTranslation } from 'next-i18next'

import type { QaHook } from '@knauf-group/ct-designs/utils/types'
import { Refresh } from '@mui/icons-material'
import { Button, ListSubheader, Typography } from '@mui/material'

type SubheaderProps = {
  title: string
  onReset?: () => void
  resetButtonDisabled?: boolean
} & QaHook

export const Subheader: React.FC<SubheaderProps> = ({
  title,
  onReset,
  resetButtonDisabled,
  dataCy,
}) => {
  const { t } = useTranslation('download-center', {
    keyPrefix: 'mainPage.filtersPanel.buttons',
  })

  return (
    <>
      <ListSubheader component="div" disableSticky>
        <Typography
          variant="body1"
          fontWeight={600}
          color="text.primary"
          textOverflow="ellipsis"
          overflow="hidden"
          data-cy={`${dataCy}__title`}
          fontSize="16px"
        >
          {title}
        </Typography>
      </ListSubheader>
      {onReset && (
        <Button
          sx={{ pl: 2, fontSize: '14px' }}
          onClick={onReset}
          disabled={resetButtonDisabled}
          variant="text"
          startIcon={<Refresh />}
          data-cy={`${dataCy}__reset-button`}
        >
          {t('reset')}
        </Button>
      )}
    </>
  )
}
