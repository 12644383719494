import { useTranslation } from 'next-i18next'

import type { QaHook } from '@knauf-group/ct-designs/utils/types'

import { FacetLayout, RefinementList, Subheader } from '../../shared'
import { useLanguageFacet } from './useLanguageFacet'

export const LanguageFacet = ({ dataCy }: QaHook) => {
  const { t } = useTranslation('download-center', {
    keyPrefix: 'mainPage.filtersPanel.filters',
  })
  const { items, handleItemSelect } = useLanguageFacet()

  return (
    <FacetLayout>
      <RefinementList
        items={items}
        subheader={<Subheader title={t('language')} dataCy={dataCy} />}
        onItemSelect={handleItemSelect}
        variant="radio"
        dataCy={dataCy}
      />
    </FacetLayout>
  )
}
