import { useTranslation } from 'next-i18next'

import type { QaHook } from '@knauf-group/ct-designs/utils/types'
import { ExpandLess, ExpandMore } from '@mui/icons-material'
import { Button } from '@mui/material'

type ShowMoreButtonProps = {
  isShowingMore: boolean
  onClick: () => void
} & QaHook

export const ShowMoreButton: React.FC<ShowMoreButtonProps> = ({
  isShowingMore,
  onClick,
  dataCy,
}) => {
  const { t } = useTranslation('download-center', {
    keyPrefix: 'mainPage.filtersPanel.buttons',
  })

  return (
    <Button
      sx={{ pl: 0, fontSize: '14px' }}
      variant="text"
      size="medium"
      onClick={onClick}
      endIcon={isShowingMore ? <ExpandLess /> : <ExpandMore />}
      data-cy={`${dataCy}__show-more-button`}
    >
      {isShowingMore ? t('showLess') : t('showMore')}
    </Button>
  )
}
