import { useTranslation } from 'next-i18next'

import type { QaHook } from '@knauf-group/ct-designs/utils/types'
import { useAnalyticsTrackResetFilters } from 'src/hooks/analytics/useAnalyticsTracking'

import { FacetLayout, HierarchicalMenu, ShowMoreButton, Subheader } from '../../shared'
import { useProdCategoryFacet } from './useProdCategoryFacet'

export const ProdCategoryFacet = ({ dataCy }: QaHook) => {
  const { t } = useTranslation('download-center', { keyPrefix: 'mainPage.filtersPanel' })
  const facet = useProdCategoryFacet()

  const { trackResetFiltersEvent } = useAnalyticsTrackResetFilters({
    includedAttributes: ['division'],
  })

  const onClickReset = () => {
    trackResetFiltersEvent(t('buttons.reset'))
    facet.clearRefinements()
  }

  return (
    <FacetLayout
      hideShowMoreButton={!facet.canToggleShowMore}
      showMoreButton={
        <ShowMoreButton
          isShowingMore={facet.isShowingMore}
          onClick={facet.toggleShowMore}
          dataCy={dataCy}
        />
      }
    >
      <HierarchicalMenu
        items={facet.items}
        subheader={
          <Subheader
            title={t('filters.productCategory')}
            resetButtonDisabled={!facet.canRefine}
            onReset={onClickReset}
            dataCy={dataCy}
          />
        }
        onItemSelect={facet.handleItemSelect}
        dataCy={dataCy}
      />
    </FacetLayout>
  )
}
