import type { PropsWithChildren } from 'react'

import { Box } from '@mui/material'

type FacetLayoutProps = {
  showMoreButton?: React.ReactNode
  hideShowMoreButton?: boolean
}

export const FacetLayout: React.FC<PropsWithChildren<FacetLayoutProps>> = ({
  children,
  showMoreButton,
  hideShowMoreButton = false,
}) => {
  return (
    <>
      {children}
      {showMoreButton && !hideShowMoreButton && (
        <Box mt={0.5} pl={2}>
          {showMoreButton}
        </Box>
      )}
    </>
  )
}
