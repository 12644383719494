import type { QaHook } from '@knauf-group/ct-designs/utils/types'
import type { Theme } from '@mui/material'
import {
  Checkbox,
  ListItem,
  ListItemButton,
  ListItemIcon,
  ListItemText,
  Radio,
  Typography,
  useMediaQuery,
} from '@mui/material'

type SelectItemProps = {
  label: string
  value: string
  isRefined: boolean
  variant: 'checkbox' | 'radio'
  onClick: () => void
} & QaHook

export const SelectItem = (props: SelectItemProps) => {
  const { label, value, isRefined, variant, onClick, dataCy } = props
  const isMobile = useMediaQuery<Theme>((theme) => theme.breakpoints.down('md'))

  return (
    <ListItem disablePadding>
      <ListItemButton
        sx={{
          paddingY: 3,
          height: isMobile ? '32px' : '30px',
        }}
        role={undefined}
        onClick={onClick}
        dense
        data-cy={`${dataCy}__button__${value}`}
      >
        <ListItemIcon sx={{ minWidth: '24px', width: '24px', marginRight: '5px' }}>
          {variant === 'checkbox' && (
            <Checkbox
              edge="start"
              size={isMobile ? 'small' : 'medium'}
              checked={isRefined}
              disableRipple
              data-cy={`${dataCy}__checkbox__${value}`}
              color="secondary"
            />
          )}
          {variant === 'radio' && (
            <Radio
              edge="start"
              size={isMobile ? 'small' : 'medium'}
              sx={{
                color: 'secondary',
                '&.Mui-checked': {
                  color: 'secondary.main',
                },
              }}
              checked={isRefined}
              disableRipple
              data-cy={`${dataCy}__radio__${value}`}
            />
          )}
        </ListItemIcon>
        <ListItemText
          primary={
            <Typography
              color="text.primary"
              variant="body3dense"
              whiteSpace="nowrap"
              overflow="hidden"
              textOverflow="ellipsis"
            >
              {label}
            </Typography>
          }
          data-cy={`${dataCy}__label__${value}`}
        />
      </ListItemButton>
    </ListItem>
  )
}
