import { useClearRefinements, useRefinementList } from 'react-instantsearch-core'

import { useAnalyticsTracking } from '@/hooks'

type TransformItemsType = Parameters<typeof useRefinementList>[0]['transformItems']

type UseFacetProps = {
  attribute: string
  transformItems?: TransformItemsType
}

export const useFacet = ({ attribute, transformItems }: UseFacetProps) => {
  const { trackFilterEvent } = useAnalyticsTracking()
  const { items, refine, isShowingMore, canToggleShowMore, toggleShowMore } = useRefinementList(
    {
      attribute,
      limit: 5,
      showMore: true,
      transformItems,
    },
  )
  const { refine: clearRefinements, canRefine } = useClearRefinements({
    includedAttributes: [attribute],
  })

  const handleItemSelect = (value: string) => {
    const filterName = attribute.split('.')[0] // Remove locale
    trackFilterEvent(filterName, value)
    refine(value)
  }

  return {
    items,
    canRefine,
    handleItemSelect,
    isShowingMore,
    canToggleShowMore,
    toggleShowMore,
    clearRefinements,
  }
}
