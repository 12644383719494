import type { QaHook } from '@knauf-group/ct-designs/utils/types'
import { ListItem, ListItemButton, ListItemText, Typography } from '@mui/material'

type MenuItemProps = {
  label: string
  value: string
  level: number
  isRefined: boolean
  canExpand: boolean
  onClick: () => void
} & QaHook

const styles = {
  width: '350px',
  textOverflow: 'ellipsis',
  overflow: 'hidden',
  whiteSpace: 'nowrap',
  color: 'text.primary',
  display: 'block',
}

export const MenuItem: React.FC<MenuItemProps> = (props) => {
  const { label, value, isRefined, onClick, dataCy } = props

  return (
    <ListItem sx={{ pl: 1 }}>
      <ListItemButton
        sx={{
          paddingX: 1,
          paddingY: 0,
          height: '30px',
          backgroundColor: isRefined ? 'action.selected' : 'transparent',
        }}
        role={undefined}
        selected={isRefined}
        onClick={onClick}
        dense
        data-cy={`${dataCy}__button__${value}`}
      >
        <ListItemText
          primary={
            // TODO do we need to specify fontWeight?
            <Typography fontWeight="400" variant="body3dense" sx={styles}>
              {label}
            </Typography>
          }
        />
      </ListItemButton>
    </ListItem>
  )
}
