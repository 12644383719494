import { useClearRefinements, useHierarchicalMenu } from 'react-instantsearch-core'
import { useRouter } from 'next/router'

import { getLanguageAndCountry } from '@/utils'

export const useProdCategoryFacet = () => {
  const attribute = 'productCategories'
  const { locale } = useRouter()
  const { languageCode } = getLanguageAndCountry(locale)
  const attributeLevels = [
    `${attribute}.lvl0.${languageCode}`,
    `${attribute}.lvl1.${languageCode}`,
    `${attribute}.lvl2.${languageCode}`,
  ]

  const { items, refine, isShowingMore, canToggleShowMore, toggleShowMore } =
    useHierarchicalMenu({
      attributes: attributeLevels,
      limit: 5,
      showMore: true,
    })
  const { refine: clearRefinements, canRefine } = useClearRefinements({
    includedAttributes: attributeLevels,
  })

  const handleItemSelect = (value: string) => refine(value)

  return {
    items,
    isShowingMore,
    canToggleShowMore,
    toggleShowMore,
    clearRefinements,
    canRefine,
    handleItemSelect,
  }
}
