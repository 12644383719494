import type { useHierarchicalMenu } from 'react-instantsearch-core'

import type { QaHook } from '@knauf-group/ct-designs/utils/types'
import { Box, List, Stack } from '@mui/material'

import { NoResultsText } from '../NoResultsText'
import { MenuItem } from './MenuItem'

type HierarchicalMenuProps = {
  items: ReturnType<typeof useHierarchicalMenu>['items']
  subheader?: React.ReactNode
  level?: number
  onItemSelect: (value: string) => void
} & QaHook

export const HierarchicalMenu: React.FC<HierarchicalMenuProps> = ({
  items,
  subheader,
  level = 0,
  onItemSelect,
  dataCy,
}) => {
  return (
    <List subheader={subheader} sx={{ border: 'none' }} data-cy={dataCy}>
      {items.length === 0 ? (
        <NoResultsText dataCy="product-category" />
      ) : (
        items.map(({ value, label, isRefined, data }) => (
          <Box key={value}>
            <MenuItem
              label={label}
              value={value}
              level={level}
              isRefined={isRefined}
              canExpand={!!data}
              onClick={() => onItemSelect(value)}
              dataCy={dataCy}
            />
            {data && (
              <Stack pl={2}>
                <HierarchicalMenu
                  items={data}
                  level={level + 1}
                  onItemSelect={onItemSelect}
                  dataCy={dataCy}
                />
              </Stack>
            )}
          </Box>
        ))
      )}
    </List>
  )
}
