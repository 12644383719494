import { useMemo } from 'react'
import { useClearRefinements, useRefinementList } from 'react-instantsearch-core'
import { useRouter } from 'next/router'

import { capitalize } from 'lodash'
import { maxFacetHits } from 'src/utils/plain/algoliaConstants'

import { useAnalyticsTracking } from '@/hooks'
import { getLanguageAndCountry } from '@/utils'

type RefinementListItems = ReturnType<typeof useRefinementList>['items']

const generateTransformer = (language: string) => {
  const languageNames = new Intl.DisplayNames([language], { type: 'language' })

  return (items: RefinementListItems) => {
    return items.map((item) => ({
      ...item,
      label: capitalize(languageNames.of(item.label)) || item.label,
    }))
  }
}

export const useLanguageFacet = () => {
  const { locale } = useRouter()
  const { languageCode } = getLanguageAndCountry(locale)
  const { trackFilterEvent } = useAnalyticsTracking()

  const transformItems = useMemo(() => generateTransformer(languageCode), [languageCode])
  const { items, refine, canRefine } = useRefinementList({
    attribute: 'language',
    transformItems,
    limit: maxFacetHits,
  })
  const { refine: clearRefinements } = useClearRefinements({ includedAttributes: ['language'] })

  const handleItemSelect = (value: string) => {
    clearRefinements()
    refine(value)
    trackFilterEvent('language', value)
  }

  return { items, canRefine, handleItemSelect }
}
