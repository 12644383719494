import type { useRefinementList } from 'react-instantsearch-core'

import type { QaHook } from '@knauf-group/ct-designs/utils/types'
import { List } from '@mui/material'

import { NoResultsText } from '../NoResultsText'
import { SelectItem } from './SelectItem'

type RefinementListProps = {
  items: ReturnType<typeof useRefinementList>['items']
  subheader: React.ReactNode
  variant: 'checkbox' | 'radio'
  onItemSelect: (value: string) => void
} & QaHook

export const RefinementList: React.FC<RefinementListProps> = ({
  items,
  subheader,
  variant,
  onItemSelect,
  dataCy,
}) => {
  return (
    <List subheader={subheader} sx={{ border: 'none' }} data-cy={dataCy}>
      {items.length === 0 ? (
        <NoResultsText dataCy="area-of-application" />
      ) : (
        items.map(({ value, label, isRefined }) => {
          return (
            <SelectItem
              key={value}
              label={label}
              value={value}
              variant={variant}
              isRefined={isRefined}
              onClick={() => onItemSelect(value)}
              dataCy={dataCy}
            />
          )
        })
      )}
    </List>
  )
}
