import { useMemo } from 'react'
import type { useRefinementList } from 'react-instantsearch-core'

import { useFacet } from './useFacet'

type RefinementListItems = ReturnType<typeof useRefinementList>['items']

type UseTranslatedFacetProps = {
  attribute: string
  t: any
}

const generateTransformer = (t: any) => (items: RefinementListItems) =>
  items.map((item) => ({ ...item, label: t(item.label) }))

export const useTranslatedFacet = ({ attribute, t }: UseTranslatedFacetProps) => {
  const transformItems = useMemo(() => generateTransformer(t), [t])

  const facet = useFacet({ attribute, transformItems })

  return facet
}
