import { useRouter } from 'next/router'
import { useTranslation } from 'next-i18next'

import type { QaHook } from '@knauf-group/ct-designs/utils/types'
import { useAnalyticsTrackResetFilters } from 'src/hooks/analytics/useAnalyticsTracking'

import { getLanguageAndCountry } from '@/utils'

import { FacetLayout, RefinementList, ShowMoreButton, Subheader, useFacet } from '../../shared'

export const StoreAvailabilityFacet = ({ dataCy }: QaHook) => {
  const { t } = useTranslation('download-center', { keyPrefix: 'mainPage.filtersPanel' })
  const { locale } = useRouter()
  const { languageCode } = getLanguageAndCountry(locale)
  const facet = useFacet({ attribute: `storeAvailability.${languageCode}` })

  const { trackResetFiltersEvent } = useAnalyticsTrackResetFilters({
    includedAttributes: ['division'],
  })

  const onClickReset = () => {
    trackResetFiltersEvent(t('buttons.reset'))
    facet.clearRefinements()
  }

  return (
    <FacetLayout
      hideShowMoreButton={!facet.canToggleShowMore}
      showMoreButton={
        <ShowMoreButton
          isShowingMore={facet.isShowingMore}
          onClick={facet.toggleShowMore}
          dataCy={dataCy}
        />
      }
    >
      <RefinementList
        items={facet.items}
        subheader={
          <Subheader
            title={t('filters.storeAvailability')}
            resetButtonDisabled={!facet.canRefine}
            onReset={onClickReset}
            dataCy={dataCy}
          />
        }
        onItemSelect={facet.handleItemSelect}
        variant="checkbox"
        dataCy={dataCy}
      />
    </FacetLayout>
  )
}
