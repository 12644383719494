import { useTranslation } from 'next-i18next'

import type { QaHook } from '@knauf-group/ct-designs/utils/types'
import { useAnalyticsTrackResetFilters } from 'src/hooks/analytics/useAnalyticsTracking'

import {
  FacetLayout,
  RefinementList,
  ShowMoreButton,
  Subheader,
  useTranslatedFacet,
} from '../../shared'

export const DivisionFacet = ({ dataCy }: QaHook) => {
  const { t: tDC } = useTranslation('download-center', { keyPrefix: 'mainPage.filtersPanel' })
  const { t: tDA } = useTranslation('dam-assets', { keyPrefix: 'divisions' })

  const facet = useTranslatedFacet({ attribute: 'division', t: tDA })

  const { trackResetFiltersEvent } = useAnalyticsTrackResetFilters({
    includedAttributes: ['division'],
  })

  const onClickReset = () => {
    trackResetFiltersEvent(tDC('buttons.reset'))
    facet.clearRefinements()
  }

  return (
    <FacetLayout
      hideShowMoreButton={!facet.canToggleShowMore}
      showMoreButton={
        <ShowMoreButton
          isShowingMore={facet.isShowingMore}
          onClick={facet.toggleShowMore}
          dataCy={dataCy}
        />
      }
    >
      <RefinementList
        items={facet.items}
        subheader={
          <Subheader
            title={tDC('filters.division')}
            resetButtonDisabled={!facet.canRefine}
            onReset={onClickReset}
            dataCy={dataCy}
          />
        }
        onItemSelect={facet.handleItemSelect}
        variant="checkbox"
        dataCy={dataCy}
      />
    </FacetLayout>
  )
}
